body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Cognito */
.amplify-tabs {
  display: none;
}

/* .amplify-button {
  background-color: #0057ff;
}
.amplify-button:hover {
  background-color: #4d89fc;
}

.amplify-button--link {
  background-color: #ffffff;
} */

:root {
  --amplify-components-authenticator-router-border-color: #ffff;
  --amplify-components-authenticator-router-box-shadow: false;
}